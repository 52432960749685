import logo from './logo.svg';
import './App.css';
import Header from './components/headers';
import Home from './modules/home';
import Footer from './components/Footer/Footer';
import { Routes, Route } from 'react-router-dom'; 
import Product from './modules/Product/Product';

function App() {
  return (
    <div>
      <Header/>
      <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/products/:id" exact element={<Product/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
