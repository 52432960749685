import React, { useEffect, useState } from "react";
import Hero from "../../components/hero";
import Products from "../../components/Products/Products";
import Features from "../../components/Features/Features";
import StatCard from "../../components/StatCard/StatCard";

const Home = () => {
  return (
    <>
      <Hero />
      <div className="flex flex-col text-center w-full mt-20">
        <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
          PRODUCTS
        </h2>
        <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">
          MOST POPULAR PRODUCTS
        </h1>
      </div>
      <Products />
      <Features />
      <StatCard />
    </>
  );
};

export default Home;
